export const columns = [
{
    title: '门店名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '门头照片',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'imageUrl'}

},
{
    title: '门店代码',
    dataIndex: 'storeCode',
    key: 'storeCode',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
  title: '员工数量',
  dataIndex: 'staffNum',
  key: 'staffNum',
  /*width:150,*/
  align:'center',
  scopedSlots: { customRender: 'staffNum'}
},
{
    title: '联系人',
    dataIndex: 'contacts',
    key: 'contacts',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '联系电话',
    dataIndex: 'mobile',
    key: 'mobile',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
  {
    title: '详细地址',
    dataIndex: 'province',
    key: 'province',
    width: 350,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'province'}
  },
]
